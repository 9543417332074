// extracted by mini-css-extract-plugin
export var container = "bxWDGhie";
export var section = "JAwDuj8Q";
export var back = "dkHjYOxP";
export var content = "gwatIQFO";
export var npm = "TcLMqjoj";
export var body = "qq7G1U3N";
export var form = "Z3ZFCcZn";
export var form__full = "zaMRplVl";
export var link = "QSNYO-tr";
export var controls = "cQFrgLNQ";
export var btn = "g0cNrUR-";